import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Image from '../../../../../../components/Image/Image';
import './SliderBots.scss';
import Link from '../../../../../../i18n/Link';

const listBots = [
  {name: 'abc', image: 'abc.svg', link: '/bot-platform/apple-messages-for-business'},
  {name: 'telegram', image: 'telegram@2x.png', link: '/bot-platform/telegram'},
  {name: 'viber', image: 'viber@2x.png', link: '/bot-platform/viber'},
  {name: 'whatsapp', image: 'messenger@2x.png', link: '/bot-platform/facebook'},
  {name: 'line', image: 'line.png'},
  {name: 'abc2', image: 'abc.svg', link: '/bot-platform/apple-messages-for-business'},
  {name: 'telegram2', image: 'telegram@2x.png', link: '/bot-platform/telegram'},
  {name: 'viber2', image: 'viber@2x.png', link: '/bot-platform/viber'},
  {name: 'whatsapp2', image: 'messenger@2x.png', link: '/bot-platform/facebook'},
  {name: 'line2', image: 'line.png'},
];

class SliderBots extends PureComponent {
  constructor() {
    super();
    this._handleStartAutoScroll();
  }
  componentDidMount() {
    if (this.interval) return;
    this._handleStartAutoScroll();
  }

  _handleStartAutoScroll() {
    if (!this.container) return;
    // let rotateLeft = this.container.rotateLeft;
    let rotateLeft = 0;
    const speedScroll = 36;
    this.interval = setInterval(() => {
      this.container.style.transform = `rotate(-${rotateLeft}deg)`;
      listBots.forEach(item => {
        if (!this[`item_${item.name}`]) return;
        this[`item_${item.name}`].style.transform = `rotate(${rotateLeft}deg)`;
      });
      rotateLeft += speedScroll;
    }, 3000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    return (
      <div className="sliderBots">
        <div className="sliderBots__logo">
          <Image file={'corezoid-logo-simple.svg'}/>
        </div>
        <div className="sliderBots__bots" ref={el => (this.container = el)}>
          {listBots.map((item, key) => (
            <React.Fragment key={key}>
              {item.link ? (
                <Link to={item.link}>
                  <div
                    className={`sliderBots__item ${item.name}`}
                    ref={el => (this[`item_${item.name}`] = el)}
                  >
                    <Image file={item.image}/>
                  </div>
                </Link>
              ) : (
                <div
                  className={`sliderBots__item ${item.name}`}
                  ref={el => (this[`item_${item.name}`] = el)}
                >
                  <Image file={item.image}/>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
}

SliderBots.propTypes = {
  intl: PropTypes.object,
  content: PropTypes.object,
};

export default injectIntl(SliderBots);

