import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './Enterprise.scss';
import Image from '../../../../components/Image/Image';

class Enterprise extends PureComponent {
  render() {
    const { enterprise, intl, bg } = this.props;

    return (
      <div className="enterprise__container" style={{background: bg}}>
        <div className="enterprise">
          <h2 className="enterprise__title">
            {intl.formatMessage({id: enterprise.title})}
          </h2>
          <div className="enterprise__list">
            {enterprise.list.map(item => (
              <div key={item.key} className="enterprise__item">
                <Image className="enterprise__item__image" file={item.image}/>
                <div className="enterprise__item__title">{intl.formatMessage({id: item.title})}</div>
                <div className="enterprise__item__subTitle">{intl.formatMessage({id: item.subTitle})}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Enterprise.propTypes = {
  enterprise: PropTypes.object,
  bg: PropTypes.string,
  intl: PropTypes.object
};

export default injectIntl(Enterprise);

