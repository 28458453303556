import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Header from './components/Header';
import Clients from '../../../../components/Clients';
import Content from './components/Content';
import './BotPlatform.scss';
import Enterprise from '../../components/Enterprise';
import Bots from './components/Bots';
import Form from '../../components/Form';

class BotPlatform extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { content, data, intl } = this.props;
    return (
      <section className="botPlatform">
        <Header
          content={content.header}
          list={content.list}
          images={data}
        />
        <Clients/>
        <Content
          content={content.list}
          intl={intl}
        />
        <Enterprise enterprise={content.enterprise} bg={'#fff'}/>
        <Bots bots={content.bots}/>
        <Form form={content.form} intl={intl}/>
      </section>
    );
  }
}

BotPlatform.propTypes = {
  data: PropTypes.object,
  content: PropTypes.object,
  intl: PropTypes.object,
};

export default BotPlatform;

