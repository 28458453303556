import React from 'react';
import PropTypes from 'prop-types';
import BotPlatform from '../../templates/BotPlatform/pages/Main';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
const host = process.env.GATSBY_HOST;

const index = {
  header: {
    title: 'botPlatformTitle',
    subTitle: 'botPlatformSubTitle',
  },
  list: [
    {
      key: 'messenger',
      title: 'botPlatformMessengerTitle',
      subTitle: 'botPlatformMessengerSubTitle',
      image: 'messenger.png',
    },
    {
      key: 'logic',
      title: 'botPlatformLogicTitle',
      subTitle: 'botPlatformLogicSubTitle',
      image: 'logic.png',
    },
    {
      key: 'bot',
      title: 'botPlatformBotTitle',
      subTitle: 'botPlatformBotSubTitle',
      image: 'bot.png',
    },
    {
      key: 'contactCenter',
      title: 'botPlatformContactCenterTitle',
      subTitle: 'botPlatformContactCenterSubTitle',
      image: 'contact-center.png',
    },
    {
      key: 'integration',
      title: 'botPlatformIntegrationTitle',
      subTitle: 'botPlatformIntegrationSubTitle',
      image: 'integration.png',
    },
    {
      key: 'analytic',
      title: 'botPlatformAnalyticTitle',
      subTitle: 'botPlatformAnalyticSubTitle',
      image: 'analytic.png',
    }
  ],
  enterprise: {
    title: 'botPlatformEnterpriseTitle',
    list: [
      {
        key: 'security',
        title: 'botPlatformEnterpriseSecurityTitle',
        subTitle: 'botPlatformEnterpriseSecuritySubTitle',
        image: 'security.png',
      },
      {
        key: 'performance',
        title: 'botPlatformEnterprisePerformanceTitle',
        subTitle: 'botPlatformEnterprisePerformanceSubTitle',
        image: 'performance.png',
      },
      {
        key: 'scale',
        title: 'botPlatformEnterpriseScaleTitle',
        subTitle: 'botPlatformEnterpriseScaleSubTitle',
        image: 'scale.png',
      }
    ]
  },
  bots: {
    title: 'botPlatformBotsTitle',
    list: [
      {key: 'portmone', image: 'portmone.png', screen: 'screen-portmone.png'},
      {key: 'varus', image: 'varus.png', screen: 'screen-varus.png'},
      {key: 'metro', image: 'metro.png', screen: 'screen-metro.png'},
      {key: 'webbankir', image: 'webbankir.png', screen: 'screen-webbankir.png'},
      {key: 'pumb', image: 'pumb.png', screen: 'screen-pumb.png'},
      {key: 'jooble', image: 'jooble.png', screen: 'screen-jooble.png'},
      {key: 'atb', image: 'atb.png', screen: 'screen-atb.png'},
      {key: 'novaPoshta', image: 'nova-poshta.png', screen: 'screen-nova-poshta.png'},
      {key: 'comfy', image: 'comfy.png', screen: 'screen-comfy.png'},
      {key: 'novus', image: 'novus.png', screen: 'screen-novus.png'},
      {key: 'iqos', image: 'iqos.png', screen: 'screen-iqos.png'},
      {key: '1+1', image: '1+1.png', screen: 'screen-1+1.png'},
    ]
  },
  form: {
    type: 'Telegram',
    text: 'botPlatformStartTitle',
    phoneLeft: 'telegram_phone-left.png',
    phoneRight: 'telegram_phone-right.png',
  }
};

const IndexPage = (props) => (
  <Layout page={'bot-platform'} {...props}>
    <BotPlatform
      content={index}
      data={props.data}
      {...props}
    />
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.object
};

export default withIntl(IndexPage);
