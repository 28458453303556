import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './Form.scss';
import Image from '../../../../components/Image/Image';
import Form from '../../../../components/Form/Form';
const host = process.env.GATSBY_HOST;

const form = {
  title: '',
  type: 'botPlatform',
  fields: [
    {
      type: 'firstName',
      label: 'formFirstName',
      value: ''
    },
    {
      type: 'lastName',
      label: 'formLastName',
      value: ''
    },
    {
      type: 'company',
      label: 'formCompany',
      value: ''
    },
    {
      type: 'email',
      label: 'formEmail',
      value: ''
    },
    {
      type: 'message',
      label: 'formMessage',
      value: ''
    },
  ],
  button: {
    text: 'formSend',
    link: `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`,
  }
};


class FormBot extends PureComponent {
  render() {
    const { intl } = this.props;
    const {type, text, phoneRight, phoneLeft} = this.props.form;
    form.type = `botPlatform${type}`;
    return (
      <div className="form__start__container">
        <div className="form__start">
          <h2 className="form__start__title">
            {intl.formatMessage({id: text})}
          </h2>
          <div className="form__start__wrap">
            <Form data={form} />
          </div>
        </div>
        <Image className="form__start__leftImage" file={`bot/${intl.locale}/${phoneLeft}`}/>
        <Image className="form__start__rightImage" file={`bot/${intl.locale}/${phoneRight}`}/>
      </div>
    );
  }
}

FormBot.propTypes = {
  form: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(FormBot);

