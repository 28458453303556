import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import './Bots.scss';
import Image from '../../../../../../components/Image/Image';

class Bots extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: 0
    };
  }

  componentDidMount() {
    this.slideAction();
  }

  slideAction() {
    this.interval = setInterval(() => {
      const { bots } = this.props;
      const {active} = this.state;
      const newActive = bots.list[active + 1] ? active + 1 : 0;
      this.setState({
        active: newActive
      });
    }, 1700);
  }

  stopSlider() {
    clearInterval(this.interval);
  }

  handleHover(index) {
    this.setState({
      active: index
    });
  }

  render() {
    const { bots, intl } = this.props;
    const { active } = this.state;

    return (
      <div className="botPlatform__bots__container">
        <div className="botPlatform__bots grid-wrapper">
          <div className="col-3 botPlatform__bots__screens">
            <div style={{position: 'relative'}}>
              <Image file={'iphone.png'}/>
              <div className="botPlatform__bots__screens__background">
                <Image file={bots.list[active].screen}/>
              </div>
            </div>
          </div>
          <div className="botPlatform__bots__content col-9">
            <h2 className="botPlatform__bots__title">
              {intl.formatMessage({id: bots.title})}
            </h2>
            <div
              className="botPlatform__bots__list"
              onMouseEnter={() => this.stopSlider()}
              onMouseLeave={() => this.slideAction()}
            >
              {bots.list.map((item, index) => (
                <div
                  key={item.key}
                  onMouseEnter={() => this.handleHover(index)}
                  className={cn('botPlatform__bots__item', {active: index === active})}
                >
                  <Image className="botPlatform__bots__item__image" file={item.image}/>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Bots.propTypes = {
  bots: PropTypes.object,
  intl: PropTypes.object
};

export default injectIntl(Bots);

