import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import './Content.scss';
import Image from '../../../../../../components/Image/Image';
import Utils from '../../../../../../utils/utils';
import allSlides from '../../../../../../constants/slides';
import Link from '../../../../../../i18n/Link';

class Content extends PureComponent {
  constructor(props) {
    super(props);
    const renderSlides = allSlides[props.intl.locale];
    this.state = {
      renderSlides,
    };
  }

  render() {
    const { content, intl } = this.props;
    // const { renderSlides } = this.state;
    const partToSlide = {};
    // renderSlides.forEach(s => {
    //   switch (s.text) {
    //     case 'sliderSpaceBank':
    //       partToSlide.messenger = s;
    //       break;
    //     case 'sliderMambu':
    //       partToSlide.analytic = s;
    //       break;
    //     case 'sliderPumb':
    //       partToSlide.logic = s;
    //       break;
    //     case 'sliderTas':
    //       partToSlide.bot = s;
    //       break;
    //     case 'sliderMetro':
    //       partToSlide.contactCenter = s;
    //       break;
    //     case 'sliderLanit':
    //       partToSlide.integration = s;
    //       break;
    //     default:
    //   }
    // });
    return (
      <div className="botPlatform__content">
        {content.map((item, index) => {
          if (item.key === 'knowledge' || item.key === 'events') {
            return <div key={index}/>;
          }

          return (
            <div key={index} className={cn('botPlatform__content__item__wrap', {greeyBG: index % 2})}>
              <div key={index} className="botPlatform__content__item">
                <div className="botPlatform__content__item__top">
                  <div className="botPlatform__content__item__content">
                    <h2
                      className="botPlatform__content__title"
                      dangerouslySetInnerHTML={{
                        __html: Utils.bbCodeToHtml(intl.formatMessage({id: item.title}))
                      }}
                    />
                    <span
                      className="botPlatform__content__subTitle"
                      dangerouslySetInnerHTML={{
                        __html: Utils.bbCodeToHtml(intl.formatMessage({id: item.subTitle}))
                      }}
                    />
                  </div>
                  <div className="botPlatform__content__item__image">
                    <Image file={item.image}/>
                  </div>
                </div>
                <div>
                  {partToSlide[item.key] &&
                    <div className="botPlatform__content__slide">
                      <div>
                        {'"'}
                        <span
                          className="botPlatform__content__slide__text"
                          dangerouslySetInnerHTML={{
                            __html: Utils.bbCodeToHtml(intl.formatMessage({
                              id: partToSlide[item.key].text
                            }))
                          }}
                        />
                        {'"'}
                      </div>
                      <div className="botPlatform__content__slide__bottom">
                        <Image className="botPlatform__content__slide__image" file={partToSlide[item.key].img}/>
                        <div className="botPlatform__content__slide__bottom__name">
                          <Image className="botPlatform__content__slide__bottom__name__image" file={partToSlide[item.key].avatar}/>
                          <div>{intl.formatMessage({id: partToSlide[item.key].name})},</div>
                          <div>{intl.formatMessage({id: partToSlide[item.key].status})}</div>
                        </div>
                        <Link to={partToSlide[item.key].link} className="slider__slide__link">
                          <span>{intl.formatMessage({id: 'sliderButton'})}</span>
                        </Link>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

Content.propTypes = {
  content: PropTypes.array,
  intl: PropTypes.object
};

export default injectIntl(Content);

