import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './Header.scss';
// import Image from '../../../../../../components/Image/Image';
import SliderBots from '../SliderBots';

class Header extends PureComponent {
  render() {
    const { intl, content } = this.props;
    return (
      <div className="botPlatform__header">
        <div className="botPlatform__header__container">
          <div className="botPlatform__header__titleContainer">
            <div className={'botPlatform__header__title'}>
              <h1>{intl.formatMessage({id: content.title})}</h1>
            </div>
            <div className="botPlatform__header__subTitle">
              {intl.formatMessage({id: content.subTitle})}
            </div>
          </div>
          <div className={'botPlatform__header__container__image'}>
             <SliderBots />
            {/* <Image file={'header-bot-platform.png'} />*/}
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  intl: PropTypes.object,
  content: PropTypes.object,
};

export default injectIntl(Header);

